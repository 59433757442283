<template>
  <svg
    viewBox="0 0 24 24">
    <path
      d="M23.3535534,0.646446609 C23.5271197,0.820012961 23.5464049,1.08943736 23.4114088,1.2843055
      L23.3535534,1.35355339 L18.6238407,6.08355795 C20.3427184,7.25721346 21.9376373,8.92670372
      23.4085976,11.0920287 L23.6484177,11.4510374 C23.8672392,11.784272 23.8672397,12.2156414
      23.6484191,12.5488765 C20.3865397,17.5162922 16.5037333,20 12,20 C9.95372915,20
      8.03563864,19.4872786 6.24572847,18.4618359 L1.35355339,23.3535534 C1.15829124,23.5488155
      0.841708755,23.5488155 0.646446609,23.3535534 C0.472880258,23.179987 0.453595107,22.9105626
      0.588591159,22.7156945 L0.646446609,22.6464466 L5.37615927,17.916442 C3.65728164,16.7427865
      2.06236269,15.0732963 0.591402424,12.9079713 L0.35158228,12.5489626 C0.132760829,12.215728
      0.132760271,11.7843586 0.35158086,11.4511235 C3.61346028,6.48370783 7.49626666,4 12,4
      C14.0462708,4 15.9643614,4.51272135 17.7542715,5.53816406 L22.6464466,0.646446609
      C22.8417088,0.451184464 23.1582912,0.451184464 23.3535534,0.646446609
      Z M17.9029827,6.80366885 L15.8714301,8.83558206 C16.5768003,9.69752504 17,10.7993407 17,12
      C17,14.7614237 14.7614237,17 12,17 C10.7993407,17 9.69752504,16.5768003 8.83558206,15.8714301
      L6.98165631,17.7269344 C8.45938425,18.5263278 10.0223085,18.9465488 11.6740958,18.9952168
      L12,19 C16.0295058,19 19.5383547,16.8215918 22.5738637,12.3571875 L22.812,11.999
      L22.5814117,11.6539571 C21.1312861,9.5193017 19.5737049,7.90647705 17.9029827,6.80366885
      Z M12,5 C7.97049418,5 4.46164529,7.17840817 1.42613628,11.6428125 L1.187,12
      L1.41858831,12.3460429 C2.86871394,14.4806983 4.42629514,16.093523 6.09701728,17.1963311
      L8.12856994,15.1644179 C7.42319969,14.302475 7,13.2006593 7,12 C7,9.23857625 9.23857625,7
      12,7 C13.2006593,7 14.302475,7.42319969 15.1644179,8.12856994 L17.0183437,6.27306555
      C15.5406157,5.47367219 13.9776915,5.05345124 12.3259042,5.00478324 L12,5
      Z M15.1596044,9.54676096 L13.723607,10.9848911 C13.8992329,11.2824531 14,11.6294588 14,12
      C14,13.1045695 13.1045695,14 12,14 C11.6292998,14 11.2821552,13.8991464 10.9845079,13.7233808
      L9.54756177,15.1602269 C10.2248413,15.6865776 11.0758188,16 12,16 C14.209139,16 16,14.209139
      16,12 C16,11.0754545 15.6863305,10.2241705 15.1596044,9.54676096 Z M12,8 C9.790861,8
      8,9.790861 8,12 C8,12.9245455 8.31366954,13.7758295 8.84039558,14.453239
      L10.276393,13.0151089 C10.1007671,12.7175469 10,12.3705412 10,12 C10,10.8954305 10.8954305,10
      12,10 C12.3707002,10 12.7178448,10.1008536 13.0154921,10.2766192 L14.4524382,8.83977306
      C13.7751587,8.31342241 12.9241812,8 12,8 Z M12.9661943,11.7412249 L11.7412249,12.9661943
      C11.8237644,12.9882448 11.9105094,13 12,13 C12.5522847,13 13,12.5522847 13,12 C13,11.9105094
      12.9882448,11.8237644 12.9661943,11.7412249 Z M12,11 C11.4477153,11 11,11.4477153 11,12
      C11,12.0894906 11.0117552,12.1762356 11.0338057,12.2587751 L12.2587751,11.0338057
      C12.1762356,11.0117552 12.0894906,11 12,11 Z">
    </path>
  </svg>
</template>
